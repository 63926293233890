import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import map from 'lodash/map';
import Masonry from 'react-masonry-css';
import AnimateIn from '../components/animate-in';
import Text from '../components/text';
import { graphql } from 'gatsby';
import './gallery.scss';

// markup
const GalleryPage = ({ data }) => {
    const breakpointColumnsObj = {
        default: 3,
        720: 2,
        490: 1,
    };

    return (
        <Layout className="gallery-page" wide>
            <SEO title="Gallery" slug="gallery" />
            <AnimateIn onScroll>
                <>
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="image-grid"
                        columnClassName="image-grid--column"
                    >
                        {map(data?.allFile?.edges, (image, index) => (
                            <Img
                                key={index}
                                className="image"
                                fluid={image?.node?.childImageSharp?.fluid}
                            />
                        ))}
                    </Masonry>

                    <Text size="medium">
                        Photography by{' '}
                        <a href="https://brittanygilbertphotography.com/">
                            Brittany Gilbert
                        </a>
                    </Text>
                </>
            </AnimateIn>
        </Layout>
    );
};

export default GalleryPage;

// query all images

export const query = graphql`
    query {
        allFile(
            filter: { relativeDirectory: { eq: "gallery" } }
            sort: {
                fields: [childImageSharp___fluid___originalName]
                order: ASC
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
